<template>
  <div>
    <action-header
      :is-loading="!channel"
      :actions-model="headerModel"
      :page="page"
    />

    <div class="q-pa-md">
      <form-builder :schema="schema" />
    </div>

    <sticky-bottom-header
      :is-loading="isSaveLoading"
      :is-active="hasChange"
      @back="handleDiscard"
      @save="save"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import ActionHeader from './../../components/action-header/ActionHeader'

export default {
  name: 'Channel',
  components: {
    ActionHeader
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      types: [
        { id: 'call', name: this.$t('Call') },
        { id: 'email', name: this.$t('Email') },
        { id: 'sms', name: this.$t('SMS') },
        { id: 'ivr', name: this.$t('IVR') },
        { id: 'public', name: this.$t('Public') },
        { id: 'private', name: this.$t('Private') }
      ],
      states: [
        { id: 'active', name: this.$t('Active') },
        { id: 'inactive', name: this.$t('Inactive') },
        { id: 'deleted', name: this.$t('Deleted') }
      ],
      access: [
        { id: 'public', name: this.$t('Public') },
        { id: 'private', name: this.$t('Private') }
      ],
      handlers: [
        { id: 'asterisk', name: this.$t('Asterisk') },
        { id: 'rapporto', name: this.$t('Rapporto') },
        { id: 'bots', name: this.$t('Bots') },
        { id: 'email', name: this.$t('Email') },
        { id: 'postback', name: this.$t('Postback') },
        { id: 'telefonico', name: this.$t('Telefonico') }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'channel'
    ]),
    page () {
      return {
        id: this.channel && this.channel.id,
        name: this.channel && this.channel.id
          ? this.$t('Channel')
          : this.$t('New channel')
      }
    },
    schema () {
      return {
        isLoading: !this.channel,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Name'),
                field: 'name',
                value: this.channel && this.channel.name,
                required: true,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                onChange: name => {
                  this.updateChannel({ name })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Handler'),
                field: 'handler',
                value: this.channel && this.channel.handler,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.handlers,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (handler) => {
                  this.updateChannel({ handler: handler.id })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Status'),
                field: 'state',
                value: this.channel && this.channel.state,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.states,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (state) => {
                  this.updateChannel({ state: state.id })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Type'),
                field: 'type',
                value: this.channel && this.channel.type,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.types,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (type) => {
                  this.updateChannel({ type: type.id })
                  this.hasChange = true
                }
              },
              {
                type: 'select',
                label: this.$t('Access Type'),
                field: 'access',
                value: this.channel && this.channel.access,
                wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                options: this.access,
                customLabel (row) {
                  return row && typeof row === 'object'
                    ? row.name
                    : row
                },
                onChange: (access) => {
                  this.updateChannel({ access: access.id })
                  this.hasChange = true
                }
              },
              ...Object.keys(((this.channel || {}).settings || {})).map(key => {
                const label = key[0].toUpperCase() + key.slice(1)

                return {
                  type: 'input',
                  inputType: 'text',
                  label: this.$t(label.split('-').join(' ')),
                  field: key,
                  value: this.channel.settings[key],
                  wrapperStyleClasses: 'col-12 col-md-6 q-pa-xs',
                  onChange: value => {
                    this.updateChannel({ settings: { ...this.channel.settings, [key]: value } })
                    this.hasChange = true
                  }
                }
              })
            ]
          }
        ]
      }
    },
    headerModel () {
      if (!this.channel) {
        return []
      }

      return [
        {
          section: 'BackAction',
          className: 'col-sm-1 hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col-sm-4 text-white mobile-title',
          options: [
            {
              id: 'title',
              type: 'text',
              value: this.channel && this.channel.id
                ? this.$t('Channel ID: ') + this.channel.id
                : this.$t('New Channel')
            }
          ]
        }
      ]
    }
  },
  mounted () {
    if (this.$route.params.id && !this.channel) {
      this.loadChannel(this.$route.params.id)
    }

    if (!this.$route.params.id) {
      this.setNewChannel()

      const owner = JSON.parse(localStorage.getItem('userData'))
      this.updateChannel({
        _embedded: {
          ...this.channel._embedded,
          owner
        }
      })
    }
  },
  unmounted () {
    this.setChannel(null)
  },
  methods: {
    ...mapActions([
      'loadChannel',
      'saveChannel'
    ]),
    ...mapMutations([
      'setNewChannel',
      'setChannel',
      'updateChannel'
    ]),
    refresh () {
      this.loadChannel(this.$route.params.id)
    },
    save () {
      this.isSaveLoading = true

      this.saveChannel()
        .then(() => {
          this.hasChange = false
          if (!this.channel.id) {
            this.handleBack()
          }
        })
        .finally(() => {
          this.isSaveLoading = false
        })
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    }
  }
}
</script>
